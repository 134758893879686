import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import RowWrapper from '../utils/rowWrapper';
import gsap from 'gsap';

import Header from "../components/header-section";
import ScrollTrigger from 'gsap/ScrollTrigger';

import Footer from "../components/footer";
// import Contact from '../components/contact-button';


gsap.registerPlugin(ScrollTrigger);


const Main = styled.div`

margin-top: 8rem;
overflow-x: hidden;

h1 {
  font-size: 6rem;
  color: ${props => props.theme.colors.color};
  text-align: center;


  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 3rem;
  }
}

`
const Points = styled.div`
  margin-top: 8rem;
`

const Point = styled.h2`
  font-size: 4rem;
  color: ${props => props.theme.colors.color};
  text-align: center;
  margin-bottom: 8rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2.5rem;
  }
`

const Sub = styled.span`
  font-size: 2.5rem;
  font-weight: normal;


  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.8rem;
  }
`

const Expression = styled.h2`
  font-size: 4rem;
  text-align: center;
  /* margin-bottom: 8rem; */
  font-family: "Museo Sans Rounded 500", sans-serif;
  line-height: 5rem;
  font-weight: normal;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2.4rem;
    line-height: 3rem;
    padding: 0 1rem;
  }

`

const YouContainer = styled.div`
  width: 55%;
  margin: 10rem auto;

  @media ${props => props.theme.breaks.down('md')} {
    width: 85%;
    margin: 6rem auto;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 95%;
    margin: 6rem auto;
  }
`

const YouSection = styled.div`
  /* width: 55%;
  margin: 0 auto; */
`

const YouLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  h3 {
    font-size: 10rem;
    font-family: "Museo Sans Rounded 700", sans-serif;
    color: ${props => props.theme.colors.color};
    margin-right: 3rem;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 6rem;
      font-family: "Museo Sans Rounded 500", sans-serif;
      margin-right: 0;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

`

const YouStatement = styled.div`
  position: relative;

  div {
    width: 110%;
    height: 110%;
    position: absolute;
    top: 0;
    left: -2rem;
    background-color: white;
    z-index: 5;
    transform: skewX(-45deg);
  }

  p {
    font-size: 2.8rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    p {
      font-size: 1.8rem;
      text-align: center;
    }
  }
`

const NeedContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 10rem auto 8rem auto;
  height: 25rem;
  backface-visibility: hidden;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    height: 15rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    height: 50rem;
    width: 100%;
    margin: 2rem auto 10rem auto;
  }
`

const NeedSection = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  backface-visibility: hidden;

  @media ${props => props.theme.breaks.down('sm')} {
    /* position: relative; */
    flex-direction: column;
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }
`

const Need = styled.div`
  margin: 0 4rem;
  border-bottom: 5px solid ${props => props.theme.colors.color};
  cursor: pointer;
  backface-visibility: hidden;

  h4 {
    font-family: "Museo Sans Rounded 300", sans-serif;
    font-weight: normal;
    font-size: 3rem;
    text-align: center;
    padding: 2rem 1rem;
    transition: padding .2s ease;
    will-change: padding;
    backface-visibility: hidden;
  }

  h4:hover {
    padding-bottom: 2.5rem;
    padding-top: 1.7rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin: 0 2rem;
    border-bottom: 3px solid ${props => props.theme.colors.color};

    h4 {
      font-size: 2rem;
      line-height: 2.5rem;
      padding: 1rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin: 3rem auto;

    h4 {
      font-size: 2rem;
      line-height: 2.5rem;
      padding: 2rem 1rem;
    }
  }
  
`

 
const You = ({ data }) => {

  const nc = useRef()

  const image = getImage(data.file)

  useEffect(() => {

    const grow = gsap.timeline()

    

    const boxStagger = gsap.timeline({
      scrollTrigger: {
        trigger: nc.current,
        start: "top 70%",
        toggleActions: "play none none reverse"
        
      },
      onComplete: () => {
        if (window.innerWidth > 600)
          return

        grow.fromTo(".need-section div h4", {padding: "2rem 1rem"}, {paddingBottom: "2.4rem", paddingTop: "1.8rem", repeat: -1, duration: .2, repeatDelay: .4, ease: "ease.out", yoyo: true, stagger: .2})
      }
    })
  
    boxStagger.fromTo(".need-section div", {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1, stagger: .2, ease: "back"})

    
    let pointers = gsap.utils.toArray(".pointers-you");

    pointers.forEach(cur => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cur,
          start: "top 90%",
          toggleActions: "play none none reverse"
        }
      })

      tl.fromTo(cur, {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1.2})
    })

    let youSecs = gsap.utils.toArray(".you-cover")

    youSecs.forEach(cur => {  // You lines animation
      let youTl = gsap.timeline({
        scrollTrigger: {
          trigger: cur,
          start: "top 70%",
          toggleActions: "play none none reverse"
        }
      })
      youTl.to(cur, {x: 600, duration: 1})
    })
  }, [])

 
 return (
  <Layout pageTitle="Create A Space - You">
    <Header img={image} />

    <RowWrapper>
      
      <Main>
        <h1>Who Are You?</h1>
        
        <Points>
          <Point className="pointers-you">
              You’re a homeowner <br />
            <Sub>who wants to add the last touch to the house of their dreams.</Sub>
          </Point>
          <Point className="pointers-you">
              You’re building a new home <br />
            <Sub>and you want every last detail to be incredible.</Sub>
          </Point>
          <Point className="pointers-you">
              You’re a designer <br />
            <Sub>hired to find the absolute best talent in custom design.</Sub>
          </Point>
        </Points>

        <Expression>
          Or, you know, you just care <br /> about really good closets.
        </Expression>

        <YouContainer>

        <YouSection>
          <YouLine>
            <h3>You</h3>
          <YouStatement>
            <div className="you-cover">&nbsp;</div>
            <p>might have very specific <br /> closet needs you want met.</p>
          </YouStatement>
          </YouLine>
        </YouSection>

        <YouSection>
          <YouLine>
            <h3>You</h3>
            <YouStatement>
            <div className="you-cover">&nbsp;</div>
            <p>might have been dreaming about <br /> your custom closet for years.</p>
          </YouStatement>
          </YouLine>
          
        </YouSection>
        <YouSection>
          <YouLine>
            <h3>You</h3>
            <YouStatement>
            <div className="you-cover">&nbsp;</div>
            <p>might have no idea what you want, <br /> but you want something special.</p>
            </YouStatement>
          </YouLine>
          
        </YouSection>
        
        </YouContainer>

        <Expression>
          Or, you know, you just want something <br /> good to put your clothes in.
        </Expression>

        <NeedContainer ref={nc}>
          <NeedSection className="need-section">
            <Need>
              <h4>You need an organized home to have an organized mind.</h4>
            </Need>
            <Need>
              <h4>You need a closet that looks better than your neighbor’s.</h4>
            </Need>
            <Need>
              <h4>You need to feel good when you open your closet.</h4>
            </Need>
          </NeedSection>
        </NeedContainer>

        <Expression>
          Or, you know, you just <br /> want something special.
        </Expression>

      </Main>
    </RowWrapper>

    <Footer statement="Whoever you are, we’d like to help you create your space." />
  </Layout>
 )
}

export default You;


export const query = graphql`
query YouQuery {
  file(relativePath: { eq: "header-you.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1850, height: 1000, quality: 100)
      }
    }
  }
`